<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            label="계기번호"
            name="valveRuptureNo"
            v-model="searchParam.valveRuptureNo">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="내용물"
            name="inMaterial"
            v-model="searchParam.inMaterial">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 관련공정 -->
          <c-process
            label="관련공정"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 관리부서 -->
          <c-dept
            type="search"
            label="관리부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <!-- 안전밸브 및 파열판 목록 -->
    <c-table
      ref="valveRupture"
      title="안전밸브 및 파열판 목록"
      tableId="valveRupture"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      rowKey="psiValveRuptureId"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable"
            label="엑셀업로드"
            icon="upload"
            @btnClicked="excelUploadData" />
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="equipmentPopup" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'valve-rupture',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'valveRuptureNo',
            field: 'valveRuptureNo',
            // 계기번호
            label: '계기번호',
            align: 'center',
            style: 'width:120px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'inMaterial',
            field: 'inMaterial',
            // 내용물
            label: '내용물',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'conditionVal',
            field: 'conditionVal',
            // 상태
            label: '상태',
            align: 'center',
            style: 'width:60px',
            sortable: true,
          },
          {
            name: 'outVolume',
            field: 'outVolume',
            // 배출용량(kg/hr)
            label: '배출용량(kg/hr)',
            align: 'center',
            style: 'width:100px',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'regulVolume',
            field: 'regulVolume',
            // 정격용량(kg/hr)
            label: '정격용량(kg/hr)',
            align: 'center',
            style: 'width:100px',
            type: 'cost',
            sortable: true,
          },
          {
            // 노즐크기
            label: '노즐크기',
            align: 'center',
            child: [
              {
                name: 'nozzleSizeIn',
                field: 'nozzleSizeIn',
                // 입구
                label: '입구',
                align: 'center',
                sortable: true,
                style: 'width:60px',
              },
              {
                name: 'nozzleSizeOut',
                field: 'nozzleSizeOut',
                // 출구
                label: '출구',
                align: 'center',
                sortable: true,
                style: 'width:60px',
              },
            ]
          },
          {
            // 보호기기압력
            label: '보호기기압력',
            align: 'center',
            child: [
              {
                name: 'protectSerial',
                field: 'protectSerial',
                // 기기번호
                label: '기기번호',
                align: 'center',
                sortable: true,
                style: 'width:80px',
              },
              {
                name: 'protectOperation',
                field: 'protectOperation',
                // 운전(MPa)
                label: '운전(MPa)',
                align: 'center',
                sortable: true,
                style: 'width:60px',
              },
              {
                name: 'protectDesign',
                field: 'protectDesign',
                // 설계(MPa)
                label: '설계(MPa)',
                align: 'center',
                sortable: true,
                style: 'width:60px',
              },
            ]
          },
          {
            // 안전밸브 등
            label: '안전밸브 등',
            align: 'center',
            child: [
              {
                name: 'valveSetting',
                field: 'valveSetting',
                // 설정(Mpa)
                label: '설정(Mpa)',
                align: 'center',
                style: 'width:60px',
                sortable: true,
              },
              {
                name: 'safetyValveMaterial',
                field: 'safetyValveMaterial',
                // 몸체재질
                label: '몸체재질',
                align: 'center',
                style: 'width:80px',
                sortable: true,
              },
              {
                name: 'safetyValveTrim',
                field: 'safetyValveTrim',
                // Trim재질
                label: 'Trim재질',
                align: 'center',
                style: 'width:80px',
                sortable: true,
              },
            ]
          },
          {
            name: 'precisionVal',
            field: 'precisionVal',
            // 정밀도(오차범위)
            label: '정밀도(오차범위)',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'outConnect',
            field: 'outConnect',
            // 배출연결부위
            label: '배출연결부위',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'outReason',
            field: 'outReason',
            // 배출원인
            label: '배출원인',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'formVal',
            field: 'formVal',
            // 형식
            label: '형식',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        valveRuptureNo: '',
        inMaterial: '',
        processCd: '',
        deptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.psi.pfi.valve.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.equipmentPopup(row);
    },
    equipmentPopup(result) {
      this.popupOptions.target = () => import(`${"./safetyValveRuptureDiskDetail.vue"}`);
      this.popupOptions.title = '안전밸브 및 파열판 상세';   //안전밸브 및 파열판 상세
      this.popupOptions.param = {
        psiValveRuptureId: result ? result.psiValveRuptureId : '',
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    excelUploadData() {
      this.popupOptions.title = '안전밸브 및 파열판 업로드';
      this.popupOptions.target = () => import(`${'./safetyValveRuptureDiskExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          // item.plantCd = this.searchParam.plantCd;
          item.SysRevision = 1;
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
        })

        this.$http.url = transactionConfig.psi.pfi.valve.insert.url + '/excel';
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        });
      }
    },
  }
};
</script>
